import { NavLink, Link } from "react-router-dom";
import Logo from "./logo.component";
import MobileMenu from "./mobile-menu";

export default function Header() {

    return(
        <header className="fixed sm:sticky top-0 w-full z-50 py-4 bg-white border-b-2 border-b-gray-200">
            <div className="container flex justify-between items-center ">
                <Logo />
                <nav className="max-lg:hidden flex-1 flex justify-center items-center gap-x-6 text-lg">
                    <NavLink to="" className="">Home</NavLink>
                    <NavLink to="services">Services</NavLink>
                    <NavLink to="faq">Faq</NavLink>
                </nav>
                <div className="flex items-center gap-x-3 md:gap-x-6">
                    <Link className="py-2 px-6 border bg-blue-600 text-white" to="/book">
                        Book <span className="max-sm:hidden">The service</span>
                    </Link>
                    <Link className="max-sm:hidden py-2 px-6 border border-blue-600 text-blue-600" to="/book">Contact</Link>
                    <MobileMenu />                    
                </div>
            </div>
        </header>
    )
};
