
import { useState } from "react"
import { CgCamera } from "react-icons/cg";
import { PiMaskHappy } from "react-icons/pi";

import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import 'react-accessible-accordion/dist/fancy-example.css';

import { Link } from "react-router-dom";
import { Faq } from "../components";
import CustomModal from "../components/modal.component";
import { IoMdCheckboxOutline } from "react-icons/io"

export default function HomePage() {
    const [showModal, setShowModal] = useState( new URLSearchParams(window.location.search).get("checkout") === "success" ? true : false)

    return(
        <>
            { showModal && (
                <CustomModal showCloseButton onCloseButton={() => setShowModal(false)}>
                    <div className="text-center flex flex-col gap-y-5">
                        <div className="flex flex-col items-center justify-center text-center text-blue-400 uppercase">
                            {/* <FaCheckCircle className="text-4xl text-blue-500/60 font-bold" /> */}
                            <IoMdCheckboxOutline className="text-[10rem] font-bold"  />
                            <h3 className="text-3xl sm:text-4xl md:text-6xl font-semibold  mt-6 mb-4">Thank you</h3>
                            <p className="text-lg sm:text-2xl font-semibold">
                                For your order
                            </p>
                        </div>
                        <p>
                            Wait for our us, we will commeback to you, by email...
                            <br />
                        </p>
                        <p className="text-xs">
                            for any questions, don't hesitate to contact us
                            <Link to="/contact" className="mt-4 mx-auto block w-fit px-6 py-2 bg-blue-500 hover:bg-black text-white">contact us</Link>
                        </p>
                    </div>
                </CustomModal>
            )}
            <section className="relative py-16 md:pb-24 lg:py-28 bg-[#F6F6F6] flex items-center">
                <div className="container grid md:grid-cols-2 max-md:gap-y-16 md:gap-x-10 lg:gap-x-16">
                    <div className="max-md:order-2 flex flex-col gap-4 lg:gap-6">
                        <h1 className="text-[#0C1A64] text-3xl lg:text-6xl font-semibold">
                            Capture Unforgettable Moments with our 360-Degree Booths
                        </h1>
                        <p className="md:text-lg text-[#444D81]">
                            Experience the next level of event photography with our innovative 360-Degree Booths. Our state-of-the-art technology captures every angle, ensuring no moment is missed.
                        </p>
                        <div className="md:text-lg flex gap-8 text-base">
                            <button className="py-3 px-6 relative bg-[#344ED5] text-white">Book</button>
                            <Link to="services" className="py-3 px-6 relative border border-[#344ED5] text-[#344ED5]">Our Services</Link>
                            {/* <Link className="py-3 px-6 border border-white text-white" to="/services">Contact us</Link> */}
                        </div>
                    </div>
                    <div className="relative max-md:order-1 max-md:scale-90 -translate-x-4 lg:max-xl:-translate-x-8">
                        <div className="absolute top-1/2 left-1/2 -translate-y-[40%] -translate-x-[44%] w-full h-full bg-[#344ED5] rounded-[20px]"></div>
                        <div className="absolute top-1/2 left-1/2 -translate-y-[48%] -translate-x-[48%] w-full h-full bg-white rounded-[20px]" />
                        <img className="relative top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-full h-80 lg:h-[450px] object-cover rounded-[20px]" src="/imgs/hoolywoodsnapbox-dancing-event.png" alt="" />
                    </div>
                </div>
            </section>

            <section className="relative py-16 md:py-24 lg:py-28 flex items-center">
                <img src="/imgs/shapes/hollywoodsnapbox-shape-rectangle.png" alt="" className="absolute inset-0 w-full h-full object-cover" />
                <div className="relative container grid md:grid-cols-2 gap-10 lg:gap-16">
                    <span className="hidden w-fit text-[#344ED5] bg-white py-2 px-6 rounded-tr-full">
                        Immerse
                    </span>
                    <div className="relative max-md:scale-90 max-md:-translate-x-3 -translate-y-5">
                        <div className="absolute top-1/2 left-1/2 -translate-y-[40%] -translate-x-[45%] w-full h-full bg-white rounded-[20px]"></div>
                        <img className="relative top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-full h-80 lg:h-[450px] object-cover rounded-[20px]" src="/imgs/hollywoodsnapbox-happyness.png" alt="" />
                    </div>
                    <div className="flex-1 flex flex-col justify-center gap-6">
                        <span className="hidden w-fit text-[#344ED5] bg-white py-2 px-6 rounded-tr-full">
                            Immerse
                        </span>
                        <h2 className="text-3xl lg:text-5xl text-white font-semibold">
                            Capture Unforgettable Moments with our 360-Degree Booths
                        </h2>
                        <p className="md:text-lg text-white">
                            Experience the next level of event photography with our innovative 360-Degree Booths. Our state-of-the-art technology captures every angle, ensuring no moment is missed.
                        </p>
                        <p className="max-lg:hidden md:text-lg text-white">
                            Experience the next level of event photography with our innovative 360-Degree Booths. Our state-of-the-art technology captures every angle, ensuring no moment is missed.
                        </p>
                        <div>
                            <button className="md:text-lg py-3 px-6 border border-white text-white">
                                Book the service
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative bg-[#F6F6F6] pt-16 md:pt-20 lg:pt-28">
                <div className="absolute bottom-0 left-0 right-0 w-full h-1/3 bg-white"></div>
                
                <div className="container grid lg:grid-cols-2 justify-center gap-y-6 lg:gap-y-24 gap-x-10 pb-48 sm:pb-52 md:pb-60 lg:pb-80">
                    <div className="flex flex-col gap-y-6">
                        <span className="hidden w-fit bg-[#344ED5] text-white py-2 px-6 text-[16px] rounded-tr-full">
                            Captivating
                        </span>
                        <h2 className="text-[#0C1A64] text-3xl lg:text-5xl font-semibold">
                            Unforgettable Memories Created with our 360-Degree Booths
                        </h2>
                        <p className="md:text-lg text-[#444D81]">
                            Experience the next level of event photography with our innovative 360-Degree Booths. Our state-of-the-art technology captures every angle, ensuring no moment is missed.
                        </p>
                        <p className="md:text-lg text-[#444D81]">
                            Experience the next level of event photography with our innovative 360-Degree Booths. Our state-of-the-art technology captures every angle, ensuring no moment is missed.
                        </p>
                        <div className="max-lg:hidden flex gap-8 text-base">
                            <button className="text-lg py-3 px-6 bg-[#344ED5] text-white">Book the service</button>
                            {/* <Link className="py-3 px-6 border border-white text-white" to="/services">Contact us</Link> */}
                        </div>

                    </div>
                    <div className="grid sm:grid-cols-2 gap-x-6 max-md:gap-y-6 gap-y-10">                        

                        <div className="">
                            <div className="flex items-center justify-center w-16 h-16 border-[#344ED5] border rounded-full">
                                <CgCamera size={30} color="#344ED5" className="" />
                            </div>
                            <h3 className="my-4 max-md:mb-2 font-semibold md:text-lg text-[#0C1A64]">
                                Unique Experience
                            </h3>
                            <p className="max-md:text-sm text-[#444D81]">
                                Create lasting memories with our state-of-the-art 360-Degree Booths at your wedding or anniversary.
                            </p>
                        </div>
                        <div className="">
                            <div className="flex items-center justify-center w-16 h-16 border-[#344ED5] border rounded-full">
                                <PiMaskHappy size={30} color="#344ED5" className="" />
                            </div>
                            <h3 className="my-4 max-md:mb-2 font-semibold md:text-lg text-[#0C1A64]">
                                Interactive Fun
                            </h3>
                            <p className="max-md:text-sm text-[#444D81]">
                                Engage your guests with our 360-Degree Booths, making your event truly unforgettable.
                            </p>
                        </div>
                        <div className="">
                            <div className="flex items-center justify-center w-16 h-16 border-[#344ED5] border rounded-full">
                                <CgCamera size={30} color="#344ED5" className="" />
                            </div>
                            <h3 className="my-4 max-md:mb-2 font-semibold md:text-lg text-[#0C1A64]">
                                Unique Experience
                            </h3>
                            <p className="max-md:text-sm text-[#444D81]">
                                Create lasting memories with our state-of-the-art 360-Degree Booths at your wedding or anniversary.
                            </p>
                        </div>
                        <div className="">
                            <div className="flex items-center justify-center w-16 h-16 border-[#344ED5] border rounded-full">
                                <PiMaskHappy size={30} color="#344ED5" className="" />
                            </div>
                            <h3 className="my-4 max-md:mb-2 font-semibold md:text-lg text-[#0C1A64]">
                                Interactive Fun
                            </h3>
                            <p className="max-md:text-sm text-[#444D81]">
                                Engage your guests with our 360-Degree Booths, making your event truly unforgettable.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="relative container flex justify-center pb-16 md:pb-28">
                    <img src="/imgs/shapes/hollywoodsnapbox-shape-rectangle.png" alt="" className="max-md:rotate-180 absolute inset-0 w-full h-full object-fit rounded-t-[20px] md:rounded-[20px]" />

                    <div className="w-11/12 relative flex flex-col items-center gap-y-10 lg:gap-y-20">
                        <img src="/imgs/hollywoodsnapbox-memorable-love-moment.png" alt="" className=" -mt-28 sm:-mt-40 w-full h-58 sm:h-80 lg:-mt-48 lg:h-96 rounded-[20px] object-cover" />
                        <div className="max-lg:hidden h-2 w-full bg-white rounded-full"/>
                        <Tabs className="relative lg:grid items-center lg:grid-cols-2 gap-10">
                            <div className="tabs-content">
                                <TabPanel className="">
                                    <div className="relative flex-1 flex justify-center items-center w-fit scale-90">
                                        <div className="max-lg:hidden absolute top-1/2 left-1/2 -translate-y-[40%] -translate-x-[45%] w-full h-full bg-white rounded-[20px]"></div>
                                        <img className="max-lg:hidden relative w-full min-h-96 object-cover rounded-[20px]" src="/imgs/hollywoodsnapbox-sunset.png" alt="" />
                                    </div>
                                </TabPanel>
                                <TabPanel className="">
                                    <div className="relative flex-1 flex justify-center items-center w-fit scale-90">
                                        <div className="max-lg:hidden absolute top-1/2 left-1/2 -translate-y-[40%] -translate-x-[45%] w-full h-full bg-white rounded-[20px]"></div>
                                        <img className="max-lg:hidden relative w-full min-h-96 object-cover rounded-[20px]" src="/imgs/hollywoodsnapbox-memorable-love-moment.png" alt="" />
                                    </div>
                                </TabPanel>
                                <TabPanel className="">
                                    <div className="relative flex-1 flex justify-center items-center w-fit scale-90">
                                        <div className="max-lg:hidden absolute top-1/2 left-1/2 -translate-y-[40%] -translate-x-[45%] w-full h-full bg-white rounded-[20px]"></div>
                                        <img className="max-lg:hidden relative w-full min-h-96 object-cover rounded-[20px]" src="/imgs/hollywoodsnapbox-happyness.png" alt="" />
                                    </div>
                                </TabPanel>

                            </div>
                            

                            <TabList className="flex flex-col max-md:gap-y-4 max-lg:gap-y-6">
                                <Tab 
                                    selectedClassName="lg:text-white/100 relative lg:before:absolute lg:before:z-10 lg:before:top-1/2 before:left-0 before:-translate-y-1/2 before:w-1 before:h-4/5 before:bg-white before:rounded-lg" 
                                    className="text-white lg:text-white/40 lg:p-4 outline-none flex flex-col md:gap-y-2 md:cursor-pointer"
                                >
                                    <h3 className="text-lg font-semibold">
                                        Capture Memories in High-Resolution
                                    </h3>
                                    <p className="text-sm">
                                        Our 360-Degree Booths capture stunning high-resolution photos that will preserve your special moments in vivid detail. Whether it's a wedding, anniversary, or any other event, our booths ensure that every memory is beautifully captured.
                                    </p>
                                </Tab>
                                <Tab 
                                    selectedClassName="lg:text-white/100 relative lg:before:absolute lg:before:z-10 lg:before:top-1/2 before:left-0 before:-translate-y-1/2 before:w-1 before:h-4/5 before:bg-white before:rounded-lg" 
                                    className="text-white lg:text-white/40 lg:p-4 outline-none flex flex-col md:gap-y-2 md:cursor-pointer"
                                >
                                    <h3 className="text-lg font-semibold">
                                        Share on Social Media
                                    </h3>
                                    <p className="text-sm">
                                        With our Social Media Integration feature, you can instantly share your photos from our booths on your favorite social media platforms. Let your friends and family join in the celebration by easily accessing and sharing the captured memories.
                                    </p>
                                </Tab>
                                <Tab 
                                    selectedClassName="lg:text-white/100 relative lg:before:absolute lg:before:z-10 lg:before:top-1/2 before:left-0 before:-translate-y-1/2 before:w-1 before:h-4/5 before:bg-white before:rounded-lg" 
                                    className="text-white lg:text-white/40 lg:p-4 outline-none flex flex-col md:gap-y-2 md:cursor-pointer"
                                >
                                    <h3 className="text-lg font-semibold">
                                        Customize Your Branding
                                    </h3>
                                    <p className="text-sm">
                                        Make your event truly unique with our Customizable Branding option. Personalize the booth's design, interface, and photo templates to match your event's theme or showcase your brand. Leave a lasting impression on your guests with a booth that reflects your style.
                                    </p>
                                </Tab>
                            </TabList>
                        </Tabs>
                    </div>    
                </div>
            </section>

            <Faq />

            <section className="md:pb-20 lg:pb-28">
                <div className="relative container py-16 md:py-20 md:rounded-[20px] overflow-hidden">
                    <img src="/imgs/hollywoodsnapbox-vacation.png" alt="" className="absolute inset-0 w-full h-full object-cover rounded-[20px]" />
                    <div className="absolute inset-0 w-full h-full bg-black/[74%]" />
                    <div className="relative flex flex-col justify-center items-center text-center text-white gap-y-10">
                        <h3 className="text-3xl lg:text-6xl font-semibold max-w-3xl md:leading-normal">Capture Memories with 360-Degree Booths</h3>
                        <p className="text-sm md:text-base lg:text-lg max-w-2xl">
                            Step into a world of immersive entertainment and capture every angle of the excitement. Book now to elevate your event to the next level!
                        </p>

                        <div className="flex gap-8 text-base">
                            <Link className="md:text-lg py-3 px-6 bg-[#344ED5] text-white" to="/book">Book the service</Link>
                            {/* <Link className="py-3 px-6 border border-white text-white" to="/services">Contact us</Link> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};
