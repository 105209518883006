import { motion,  } from 'framer-motion';

const configs = {
    
    initial: { opacity: 0, x: -100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
    // transition: { duration: 0.5 }
}
const PageTransitionWrapper = ({children} : any) => (
    <motion.div initial="initial" animate="animate" exit="exit" variants={configs} transition={{duration: 0.5}}>
      {children}
    </motion.div>
)

export default PageTransitionWrapper