import { useCallback, useState } from "react";
import axios from "axios"
import { FaCircleNotch } from "react-icons/fa";

import { Breadcrumb, MyForm } from "../components";
import { FieldInputType } from "../components/my-form.component";
import { FaStripeS } from "react-icons/fa6";


export default function BookPage() {

    const [isLoading, setIsLoading] = useState(false)

    const onBooking = useCallback( (fields : any) => {
        setIsLoading(true)

        setTimeout( () => {            
            axios.post("https://az-tek-apis.onrender.com/booking", fields)
            .then( ({data}) => {
                data && document.location.replace(data)
            })
            .catch( er => {
                setIsLoading(false)
            })
            .finally( () => setIsLoading(false))
        }, 1000)
    }, [])

    return (
        <>

            <Breadcrumb
                pageTitle="Book the service"
                title="For any event type, we're here to help you capture memorable moments"
                maxTitleWidth="max-w-7xl"
                description=""
            />

            <section className="md:bg-[#344ED5]">
                <div className="relative overflow-hidden flex flex-col gap-y-10 mx-auto md:-translate-y-10 lg:-translate-y-16 py-16 px-4 md:px-8 max-w-2xl md:shadow-2xl bg-gray-100 md:rounded-3xl">
                    <h2 className="text-2xl md:text-4xl">
                        Save your reservation
                    </h2>
                    
                    <MyForm onSubmit={ onBooking } className="flex flex-wrap justify-between gap-y-4 gap-x-3 text-sm" onEmptyErrorMessage='this field is required' onInvalidErrorMessage='invalid value provided'                    
                        fields={{
                            name : {
                                required : true,
                                id : 'name',
                                label : 'Name',
                                placeholder : 'enter your name please',
                            },
                            phone : {
                                required : true,
                                id : 'phone',
                                label : 'Phone',
                                placeholder : '',
                                type: FieldInputType.PHONE,
                                extraData: {halfWidth: true}
                            },
                            email : {
                                required : true,
                                id : 'email',
                                label : 'Email',
                                placeholder : 'eg : yourname@domain.com',
                                type: FieldInputType.EMAIL,
                                extraData: {halfWidth: true}
                            },                          
                            bookingEventType : {
                                required : true,
                                id : 'eventType',
                                label : 'What type of event',
                                placeholder : 'choose from the list',
                                type: FieldInputType.SELECT_BOX,
                                extraData: { halfWidth: true, options: [{label: "Type One", value : "other"}, {label:"Type Two", value : "other"}, {label:"Type Three", value : "other"}, {label:"Type Four", value : "other"}]  },
                                // renderFieldComponent: InputFieldCustomSelect
                            },                       
                            bookingServiceType : {
                                required : true,
                                id : 'eventServices',
                                label : 'Witch service are you requesting for',
                                placeholder : 'choose from the list',
                                type: FieldInputType.SELECT_BOX,
                                extraData: { halfWidth: true, options: [{label: "service one"}, {label:"service two"}, {label:"service three"}, {label:"service four"}]  },
                            },                           
                            bookingAdress : {
                                required : true,
                                id : 'eventLocation',
                                label : 'Adress of event',
                                placeholder : '',
                                type: FieldInputType.TEXT,
                            },
                            bookingDate : {
                                required : true,
                                id : 'eventDate',
                                label : 'Pick a date',
                                placeholder : '',
                                type: FieldInputType.DATE,
                                extraData: { halfWidth: true },
                                onChange: field => console.log(field),
                            },                          
                            bookingDuration : {
                                required : true,
                                id : 'bookingDuration',
                                label : 'For how long (1hr to 6hr)',
                                type: FieldInputType.RANGE,
                                initialValue: '1',
                                // onChange: duration => onFormChange(duration),
                                extraData: { halfWidth: true, step: 1, min:0, max:6 },
                            },
                            
                        }}
                    >
                        <div className='w-full block'>
                            <button className="flex w-full items-center justify-center h-12 bg-blue-600 text-lg text-white">
                                proceed to payment with Stripe <FaStripeS className="" />
                            </button>
                        </div>
                    </MyForm>                    

                    <div className={`absolute inset-0 w-full h-full bg-black/60 text-white text-sm ${ isLoading ? "flex" : "hidden"} flex-col gap-y-3 justify-center items-center`}>
                        <FaCircleNotch className="text-3xl text-white animate-spin" />
                        Saving informations
                    </div>
                </div>
            </section>
            
        </>
    )
};
