import { Link } from "react-router-dom";

type props={
    classNames?: string
}
export default function Logo({classNames=""} : props) {
    
    return((
        <Link to="" className={`max-sm:h-10 h-16 ${classNames}`}>
            <img src="/hollywoodsnapbox-logo.jpeg" alt="" className="w-ful h-full object-contain" />
        </Link>
    ))
};
