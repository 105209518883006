import { FunctionComponent, useCallback, useMemo, useEffect, useRef, useState, ReactNode } from "react"
import { FaAngleUp } from "react-icons/fa";
import { Range } from "react-range";

export enum InputPatterns{
    EMAIL = "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
    PASSWORD = "(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}",
    MIN_HEIGHT_8 = "(?=.*).{8,}",
    URL = "https?://.+",
    ALPHABETS_AND_SPACES = '[a-zA-Z.\\D]+'
}

export const isMatchingPattern = (text : string, pattern : string) => (new RegExp(pattern)).test(text)

export enum FieldInputType{
    TEXT = 'text',
    PASSWORD = 'password',
    SELECT_BOX = 'selectbox',
    NUMBER = 'number',
    PHONE = 'tel',
    FILE = 'file',
    EMAIL= 'email',
    DATE='date',
    RANGE = "RANGE"
}

export enum FieldValidity{
    VALID = 'valid',
    EMPTY = 'empty',
    INVALID = 'invalid'
}

export type Field  = {
    type? : FieldInputType
    label? : string
    id? : string
    initialValue? : string
    placeholder? : string
    icon? : string
    required? : boolean
    readonly? : boolean
    pattern? : InputPatterns | string
    onChange? : (field : {name : string, value : any}) => void
    className? : string
    onEmptyErrorMessage? : string
    onInvalidErrorMessage? : string
    renderFieldComponent? : FunctionComponent<Field & {name : string, validity? : FieldValidity, hasError? : boolean}>

    extraData? : any
}

type props = {
    fields : {[x : string] : Field}
    onEmptyErrorMessage? : string
    onInvalidErrorMessage? : string
    renderFieldComponent? : FunctionComponent<Field & {name : string, validity? : FieldValidity, hasError? : boolean}>
    onChange? : (field : {name : string, value : any}) => void
    onSubmit? : ( fields : {[x : string] : any}) => void
    className? : string
    SubmitButton? : any
    children? : any
}


export type Option = {
    value? : string,
    label : string
}

type CustomSelectBoxProps = {
    id? : string
    name? : string
    placeholder? : string
    options? : Option[]
    defaultValue? : string
    onChange? : (option : Option) => void
    maxItems? : number
    search? : boolean
    sort? : boolean
    className? : string
}

export function CustomSelectBox({className = '', placeholder, name = '', id, options = [], defaultValue,maxItems = 5, onChange, search, sort} : CustomSelectBoxProps){
    const [showDropdown, setShowDropdown] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [currentOption, setCurrentOption] = useState(options.find( option => option.value === defaultValue))
    const label = useMemo( () => currentOption?.label || placeholder, [currentOption, placeholder])

    
    const isCurrent = useCallback( (optionValue : Option) => {
        if(!currentOption){
            return false
        }else if(currentOption.label === optionValue.label){
            return true
        }else{
            return false
        }
        
    }, [currentOption])

    const RenderOption = useCallback( ({option} : {option : Option}) => (
        <div 
            className={`flex items-center py-2 px-1 text-sm cursor-pointer hover:bg-gray-100 ${isCurrent(option) ? 'bg-gray-100 text-primary' : ''}`} 
            onClick={ () => setCurrentOption(option)}
        >
            {isCurrent(option) && <i className="fas fa-check mx-1 " />}
            <span className=''>{option.label}</span>
        </div>
    ), [isCurrent])

    const filter = useCallback( () => {
        return options.filter(option => {
            return !searchText ?  option : (
                 option.label.toLowerCase().includes(searchText.toLowerCase()) 
            )
        })
                        
    }, [options, searchText])

    const sorting = useCallback( (options : Option[]) => {
        return sort ? options.sort( (a, b) => a.label.localeCompare(b.label) ) : options
    }, [sort])

    useEffect( () => {
        setCurrentOption( options.find( option => option.value === defaultValue) )
    }, [defaultValue, options])

    useEffect( () => {
        setShowDropdown(false)
        currentOption && onChange && onChange(currentOption)
    }, [currentOption, onChange])

    useEffect( () => {
        document.onclick = () => setShowDropdown(false)
    }, [])

    return(
        <div className="relative" onClick={e => e.stopPropagation()}>
            <div className={`bg-white cursor-pointer flex justify-between items-center h-12 border border-primary rounded-md ${className}`} onClick={ e => {e.preventDefault(); setShowDropdown( prev => !prev)}}>
                <label className={`text-tirtiary truncate whitespace-nowrap cursor-pointer p-2 flex-1 ${!currentOption ? "text-xs" : ""}`} htmlFor="">{label}</label>
                <div className="flex justify-center items-center w-6 border-l border-l-primary h-full cursor-pointer  text-primary">
                    <FaAngleUp className={`${showDropdown ? '' : 'rotate-180'}`} />
                </div>
            </div>
            <div className={`${showDropdown ? 'block' : 'hidden'} absolute z-20 w-full bg-white rounded shadow overflow-hidden`} >
                { search && (
                        <div className="flex items-center gap-1 bg-gray-50 text-sm border">
                            <label className="fas fa-search mx-1" />
                            <input placeholder="taper pour filtrer" type="text" className="flex w-full h-8 bg-gray-100" onChange={e => setSearchText(e.target.value)} />
                        </div>
                    )
                }
                <div className="divide-y max-h-[10.5rem] overflow-y-auto">
                    {
                        sorting(filter()).map( (option, index) => <RenderOption key={index} option={option} />)
                    }

                </div>
            </div>

            <input id={id} type="hidden" name={name}  />
        </div>
    )
}

export function RangeInputField(props : Field & {name : string, validity? : FieldValidity, hasError? : boolean}){
    const [value, setValue] = useState(Number.parseInt(props.initialValue || '0'))

    useEffect( () => {
        props.onChange && props.onChange( {name:props.name, value})
    }, [props, value])

    return (
        <div>
            <Range
                values={[value]}
                step={props.extraData?.step}
                min={props.extraData?.min}
                max={props.extraData?.max} 
                onChange={ ([value]) => setValue(value)} 
                renderTrack={({ props: trackProps, children }) => (
                    <div onMouseDown={trackProps.onMouseDown} onTouchStart={trackProps.onTouchStart} className="h-12 w-full flex items-center" style={{...trackProps.style}}>
                        <div ref={trackProps.ref} className="h-2 w-full rounded-lg self-center bg-gray-300" >
                            {children}
                        </div>
                    </div>
                )}
                renderThumb={({ props: thumbProps, isDragged }) => (
                    <div {...thumbProps} style={{...thumbProps.style}} className={`${isDragged ? "bg-blue-800" : "bg-blue-600"} h-8 w-8 rounded-full flex items-center justify-center shadow-2xl`}>
                        <div className={`text- ${isDragged ? "text-blue-300" : "text-white"}`}>
                            {value}
                            <span className="text-xs">hr</span>
                        </div>
                    </div>
                )}
                            
            />
            
        </div>
    )
}
export function InputFieldTemplate1( props : Field & {name : string, validity? : FieldValidity, hasError? : boolean} ){
    return (
        <div className={`w-full ${props.extraData?.halfWidth ? "md:w-[48%]" : "" }`}>
            <div className="w-full flex flex-col gap-y-2">
                <div className="flex items-center flex-wrap w-full gap-x-2">                       
                    <label className="" htmlFor={props.id}>{props.label} {props.required  && <span className="flex-inline translate-y-full">*</span> } </label>
                    { props.hasError && (props.validity === FieldValidity.EMPTY) && <p className='text-red-500 text-xs mt-1 md:mt-0'>{props.onEmptyErrorMessage}</p>}
                </div>
                {
                    props.type === FieldInputType.RANGE ? <RangeInputField {...props} /> : props.type !== FieldInputType.SELECT_BOX ? (
                        <input  
                            className={`bg-white p-2 h-12 w-full border placeholder:text-slate-300 shadow-inner ${props.className || ""} ${props.hasError ? "border-red-500" :""}`}
                            id={props.id}
                            type={props.type} 
                            name={props.name} 
                            onChange={e => props.onChange && props.onChange({name : e.target.name, value : e.target.value})} 
                            placeholder={props.placeholder}
                            defaultValue={props.initialValue}
                            readOnly={props.readonly} 
                        />
                    ) : (
                        <select
                            className={`${props.hasError ? "border-red-500" :""} bg-white p-2 h-12 w-full border placeholder:text-slate-300 shadow-inner`}
                            id={props.id}
                            name={props.name} 
                            onChange={e => props.onChange && props.onChange({name : e.target.name, value : e.target.value})}
                            defaultValue={props.initialValue}                            
                        >
                            <option value="">{props.placeholder}</option>
                            {props.extraData?.options?.map( (opt : Option, key: number) => (
                                <option key={key} className="" value={opt.value || opt.label}>{opt.label}</option>
                            ))}
                        </select>
                    )
                }
            </div>
            { props.hasError && (props.validity === FieldValidity.INVALID) && <p className='text-red-500 text-xs '>{props.onInvalidErrorMessage}</p> }
        </div>
    )
}

export function InputFieldTextArea( props : Field & {name : string, validity? : FieldValidity, hasError? : boolean} ){
    return (
        <div className="w-full my-4">
            <div className="flex items-center flex-wrap w-full gap-2">                       
                <label className="" htmlFor="preface">{props.label}</label>
                { props.hasError && <p className='text-red-500 text-xs mt-1 md:mt-0'>{ (props.validity === FieldValidity.EMPTY) ? props.onEmptyErrorMessage : props.onInvalidErrorMessage}</p>}
            </div>
            <textarea 
                onChange={e => props.onChange && props.onChange({name : e.target.name, value : e.target.value})}
                name={props.name} 
                id={props.id} 
                rows={5} 
                placeholder={props.placeholder} // 'entrée une description du livre (aumoins 500 characters)'
                defaultValue={props.initialValue}
                className={`p-2 w-full border border-gray-50 shadow-sm rounded-md ${props.className || ""}`}
            />
        </div>
    )
}

export function InputFieldCustomSelect( props : Field & {name : string, validity? : FieldValidity, hasError? : boolean} ){
    return(
        <div className="w-full my-4">
            <div className="flex items-center flex-wrap w-full gap-2">
                <label className="" htmlFor={props.id}>{props.label}</label>
                { props.hasError && (
                    <p className='text-red-500 text-xs mt-1 md:mt-0'> { props.validity === FieldValidity.EMPTY ? props.onEmptyErrorMessage : props.onInvalidErrorMessage } </p>
                )}
            </div>
            <CustomSelectBox
                id={props.id}
                className={`bg-white border-gray-50 shadow-sm ${props.className || ""}`}
                name={props.name}
                placeholder={props.placeholder}
                defaultValue={props.initialValue}
                options={props.extraData?.options}
                onChange={ option => props.onChange && props.onChange({name : props.name, value : option.value!} ) }
            />
        </div>
    )
}

export default function MyForm({ fields, onChange, onSubmit, className, onEmptyErrorMessage,onInvalidErrorMessage, renderFieldComponent: RenderFieldComponent = InputFieldTemplate1, children} : props){
    const [states, setStates] = useState( { fields  })
    const formDatas = useRef( (() => {
        let datas : {[x : string] : string} = {}
        Object.entries(fields).forEach( ([fieldName, field]) => {
            datas[fieldName] = field.initialValue || ''
        })

        return datas
    })() ) 

    const validateField = useCallback( (field : Field, currentValue : string) => {
        
        if(field.required){
            if(!currentValue) return FieldValidity.EMPTY
        }

        if( field.pattern && !isMatchingPattern(currentValue, field.pattern) ){
            return FieldValidity.INVALID
        }

        return FieldValidity.VALID
    }, [])
    
    const handleSubmit = useCallback( (e : any) => {
        e.preventDefault()

        let anyError = false
        const newDatas : any = {}
        Object.entries(states.fields).forEach( ([fieldName, field]) => {
            const validity = validateField(field, formDatas.current[fieldName])
            newDatas[fieldName] = {
                ...fields[fieldName],
                initialValue : formDatas.current[fieldName], 
                validity, 
                hasError : validity !== FieldValidity.VALID 
            }
            anyError ||= validity !== FieldValidity.VALID
        })

        setStates( prev => ({...prev, fields : newDatas}))

        !anyError && onSubmit && onSubmit(formDatas.current)

    }, [fields, onSubmit, states.fields, validateField])
    
    const handleChange = useCallback( (field : {name : string, value : any}) => {
        formDatas.current = {...formDatas.current, [field.name] : field.value}
        onChange && onChange(field)
    }, [onChange])

    return (
        <form onSubmit={handleSubmit} className={` ${className}`} onKeyDown={ e => (e.key === 'Enter') && handleSubmit(e) } >
            { 
                Object.entries(states.fields)
                .map( ([name, field], index) => (
                    field.renderFieldComponent ? <field.renderFieldComponent key={index} {...field} name={name} onChange={handleChange} onEmptyErrorMessage={field.onEmptyErrorMessage || onEmptyErrorMessage} onInvalidErrorMessage={ field.onInvalidErrorMessage || onInvalidErrorMessage} />: <RenderFieldComponent key={index} {...field} name={name} onChange={handleChange} onEmptyErrorMessage={field.onEmptyErrorMessage || onEmptyErrorMessage} onInvalidErrorMessage={ field.onInvalidErrorMessage || onInvalidErrorMessage} />
                )) 
            }

            {children}
        </form>
    )
}