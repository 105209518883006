import { useCallback } from "react";
import { BsSendCheckFill } from 'react-icons/bs'
import MyForm, { FieldInputType, InputFieldTextArea } from "../components/my-form.component";
import { Breadcrumb, Faq } from "../components";
import { Link } from "react-router-dom";
import { FaMap, FaPhone } from "react-icons/fa";
import { MdMail } from "react-icons/md";

export default function ContactPage() {
    const onSubmit = useCallback( (fields : any) => {
        console.log("form fields ==> ", fields)

    }, [])
    
    
    return (
        <>
            <Breadcrumb 
                pageTitle="Contact" 
                title="Stay in touch with us" 
                description="At Hollywood Snapbox, we specialize in providing high-quality photobooth services that turn any event into a memorable experience." 
            />
            <section className="py-16 md:py-20 lg:py-28">
                <div className="container grid md:grid-cols-2 gap-10">
                    <div className="flex flex-col gap-y-4 lg:gap-y-6">
                        <h2 className="text-3xl lg:text-5xl text-[#0C1A64] font-semibold">
                            We're here to help you every step of the way
                        </h2>
                        <p className="lg:text-lg text-[#0C1A64]">
                            Whether you have questions about our photobooth services, need assistance with booking, or want to learn more about how we can make your next event unforgettable, our team is ready to assist. Reach out to us via phone, email, or through our online contact form.
                        </p>
                        <div className="mt-4 flex flex-col gap-y-4 md:gap-y-6">

                            <a className="flex items-center gap-2 md:gap-4" href="tel:+12132459352">
                                <div className="flex justify-center items-center w-12 md:w-16 h-12 md:h-16 bg-blue-600/10 rounded-full">
                                    <FaPhone className="text-xl md:text-3xl text-blue-600"/>
                                </div>
                                <span className="md:text-xl font-semibol">+1 (213) 245-9352</span>
                            </a>
                            <a className="flex items-center gap-2 md:gap-4" href="mailto:contact@hollywoodsnapbox.com">
                                <div className="flex justify-center items-center w-12 md:w-16 h-12 md:h-16 bg-blue-600/10 rounded-full">
                                    <MdMail className="text-xl md:text-3xl text-blue-600"/>
                                </div>
                                <span className="md:text-xl font-semibol">contact@hollywoodsnapbox.com</span>
                            </a>
                            <div className="flex items-center gap-2 md:gap-4 col-span-2">
                                <div className="flex justify-center items-center w-12 md:w-16 h-12 md:h-16 bg-blue-600/10 rounded-full">
                                    <FaMap className="text-xl md:text-3xl text-blue-600"/>
                                </div>
                                <span className="md:text-xl font-semibol">+1 (213) 245-9352</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-4">
                        
                    <span className="hidden w-fit bg-[#344ED5] text-white py-2 px-6 text-[16px] rounded-tr-full">
                            Contact
                        </span>
                        <h3 className="text-3xl font-semibold text-[#0C1A64] md:leadin-tight w-fit">
                            Drop us a line!
                        </h3>
                        
                        <MyForm onSubmit={ onSubmit } className="mt-6 flex flex-wrap justify-between gap-y-5 gap-x-3" onEmptyErrorMessage='this field is required' onInvalidErrorMessage='invalid value provided'                    
                            fields={{
                                name : {
                                    required : true,
                                    id : 'name',
                                    label : 'Name',
                                    placeholder : 'enter your name please',
                                },
                                phone : {
                                    required : true,
                                    id : 'phone',
                                    label : 'Phone',
                                    placeholder : '',
                                    type: FieldInputType.PHONE,
                                    extraData: {halfWidth: true}
                                },
                                email : {
                                    required : true,
                                    id : 'email',
                                    label : 'Email',
                                    placeholder : 'eg : yourname@domain.com',
                                    type: FieldInputType.EMAIL,
                                    extraData: {halfWidth: true}
                                },
                                message : {
                                    required : true,
                                    id : 'name',
                                    label : 'Message',
                                    placeholder : 'tell us everything you want',
                                    renderFieldComponent : InputFieldTextArea
                                }
                            }}
                        >
                            <div className='-mt-4 w-full block'>
                                <button className="py-3 px-6 bg-[#344ED5] hover:bg-[#0C1A64] text-white flex items-center gap-x-4">
                                    submit
                                    <BsSendCheckFill className='' />
                                </button>
                            </div>
                        </MyForm>
                    </div>
                </div>
            </section>

            <Faq />

            <section className="md:pb-20 lg:pb-28 bg-gray-50">
                <div className="relative container py-16 md:py-20 md:rounded-[20px] overflow-hidden">
                    <img src="/imgs/hollywoodsnapbox-vacation.png" alt="" className="absolute inset-0 w-full h-full object-cover rounded-[20px]" />
                    <div className="absolute inset-0 w-full h-full bg-black/[74%]" />
                    <div className="relative flex flex-col justify-center items-center text-center text-white gap-y-10">
                        <h3 className="text-3xl lg:text-6xl font-semibold max-w-3xl md:leading-normal">Capture Memories with 360-Degree Booths</h3>
                        <p className="text-sm md:text-base lg:text-lg max-w-2xl">
                            Step into a world of immersive entertainment and capture every angle of the excitement. Book now to elevate your event to the next level!
                        </p>

                        <div className="flex gap-8 text-base">
                            <Link className="md:text-lg py-3 px-6 bg-[#344ED5] text-white" to="/book">Book the service</Link>
                            {/* <Link className="py-3 px-6 border border-white text-white" to="/services">Contact us</Link> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};
