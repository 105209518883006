export default function Faq() {
    
    return (
        <section className="py-16 md:py-20 lg:py-28">
            <div className="container grid lg:grid-cols-2 gap-8 lg:gap-16">

                <div className="relative grid md:grid-cols-2 lg:grid-cols-1">
                    <div className="flex flex-col gap-y-4 lg:gap-y-6">
                        <h2 className="text-3xl lg:text-5xl font-semibold text-[#0C1A64] leading-snug w-fit">
                            Frequently Asked Questions
                        </h2>
                        <p className="text-sm sm:text-base lg:text-lg">
                            Here, we’ve compiled a list of the most commonly asked questions to help you understand our photobooth services better.
                            Whether you’re curious about our booking process, the features of our 360-degree photobooths, or need technical assistance, you’ll find the answers here.
                        </p>
                    </div>
                    <div className="relative max-md:-mx- scale-90 translate-y-6 translate-x-4 lg:max-xl:translate-x-8 h-80 lg:h-[350px] md:scale-60 lg:scale-100 w-[97%] md:w-[350px]">
                        <div className="absolute -top-1/2 -left-1/2 translate-y-[40%] translate-x-[44%] w-full h-full bg-[#344ED5] rounded-[20px]"></div>
                        <div className="absolute -top-1/2 -left-1/2 translate-y-[48%] translate-x-[48%] w-full h-full bg-white rounded-[20px]" />
                        <img className="relative top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-full h-full object-cover rounded-[20px]" src="/imgs/hoolywoodsnapbox-dancing-event.png" alt="" />
                    </div>

                </div>
                <div className="flex flex-col gap-4 lg:gap-y-6">
                    
                    <div className="flex flex-col gap-2">
                        <h3 className="text-xl text-[#0C1A64] font-semibold">
                            How does it work?
                        </h3>
                        <p className="lg:text-lg text-[#444D81]">
                            Our 360-Degree Booths capture stunning photos and videos from every angle, creating immersive experiences for your events. Our team will set up the booth, guide your guests, and ensure they have a memorable time.
                        </p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <h3 className="text-xl text-[#0C1A64] font-semibold">
                            What events are suitable?
                        </h3>
                        <p className="lg:text-lg text-[#444D81]">
                            Our 360-Degree Booths are perfect for weddings, anniversaries, corporate events, and any occasion where you want to create lasting memories.
                        </p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <h3 className="text-xl text-[#0C1A64] font-semibold">
                            How much does it cost?
                        </h3>
                        <p className="lg:text-lg text-[#444D81]">
                            Our pricing packages are tailored to your specific needs. Please contact us for a personalized quote.
                        </p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <h3 className="text-xl text-[#0C1A64] font-semibold">
                            Is customization available?
                        </h3>
                        <p className="lg:text-lg text-[#444D81]">
                            Yes, we offer various customization options such as branded overlays, digital props, and custom backdrops to match your event theme.
                        </p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <h3 className="text-xl text-[#0C1A64] font-semibold">
                            Do you provide props?
                        </h3>
                        <p className="lg:text-lg text-[#444D81]">
                            Yes, we have a wide selection of fun props to enhance your photo and video experiences. Our team will bring them to your event.
                        </p>
                    </div>
                </div>                
            </div>
        </section>
    )
};
