import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { Header, Footer } from "./components"
import './App.css';
import { ContactPage, HomePage, ServicesPage, FaqPage, BookPage } from './pages';
import PageTransitionWrapper from './pages/page-transition.wrapper';


function App() {
  const location = useLocation()
  useEffect(() =>{
      if(location.hash){
          document.getElementById(location.hash.substring(1))?.scrollIntoView({behavior : "smooth", inline : "start" })
      }else{
          window.scrollTo({ left:0, top: 0, behavior: "auto"} )
      }

  }, [location])

  return (
    <>
      <Header />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path='' element={ <PageTransitionWrapper> <HomePage/> </PageTransitionWrapper> } />
          <Route path='services' element={ <PageTransitionWrapper> <ServicesPage/> </PageTransitionWrapper> } />
          <Route path='contact' element={ <PageTransitionWrapper> <ContactPage/> </PageTransitionWrapper> } />
          <Route path='faq' element={ <PageTransitionWrapper> <FaqPage /> </PageTransitionWrapper> } />
          <Route path='book' element={ <PageTransitionWrapper> <BookPage /> </PageTransitionWrapper> } />
        </Routes>
        <Footer />
      </AnimatePresence>
    </>
  );
}

export default App;
