import { Breadcrumb } from "../components";

export default function ServicesPage() {
    
    return(
        <>
            <Breadcrumb 
                pageTitle="Our Services" 
                title="Discover Our Photobooth Services" 
                description="At Hollywood Snapbox, we specialize in providing high-quality photobooth services that turn any event into a memorable experience." 
            />

            <section className="py-16 md:py-20 lg:py-28 bg-gray-200">
                <div className="container grid md:grid-cols-2 gap-16">
                    <div className="flex flex-col justify-center gap-y-4 lg:gap-y-8 text-[#0C1A64]">
                        <h2 className="text-3xl lg:text-5xl font-semibold">
                            Making Memories, One Snap at a Time
                        </h2>
                        <p className="lg:text-lg">
                            Whether you're hosting a wedding, corporate event, birthday party, or any other special occasion, our state-of-the-art 360-degree photobooths capture every angle and every smile. 
                        </p>
                        <p className="lg:text-lg">
                            From instant printouts to digital sharing options, we offer a variety of customizable features to suit your needs. Explore our services and see how we can make your next event unforgettable.
                        </p>
                    </div>
                    <div className="relative max-md:order-1 max-md:scale-90 -translate-x-4 lg:max-xl:-translate-x-8 h-80 lg:h-[450px]">
                        <div className="absolute top-1/2 left-1/2 -translate-y-[40%] -translate-x-[44%] w-full h-full bg-[#344ED5] rounded-[20px]"></div>
                        <div className="absolute top-1/2 left-1/2 -translate-y-[48%] -translate-x-[48%] w-full h-full bg-white rounded-[20px]" />
                        <img className="relative top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-full h-full object-cover rounded-[20px]" src="/imgs/hoolywoodsnapbox-dancing-event.png" alt="" />
                    </div>
                </div>
            </section>

            <section className="relative py-16 md:py-20 lg:py-28">
                <img src="/imgs/shapes/hollywoodsnapbox-shape-rectangle.png" alt="" className="absolute inset-0 w-full h-full object-cover" />
                <div className="relative container grid md:grid-cols-2 gap-16">
                    <div className="relative max-md:order-1 max-md:scale-90 translate-y-6 translate-x-4 lg:max-xl:translate-x-8 h-80 lg:h-[450px]">
                        <div className="absolute -top-1/2 -left-1/2 translate-y-[40%] translate-x-[44%] w-full h-full bg-white rounded-[20px]"></div>
                        <div className="absolute -top-1/2 -left-1/2 translate-y-[48%] translate-x-[48%] w-full h-full bg-[#344ED5] rounded-[20px]" />
                        <img className="relative top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10 w-full h-full object-cover rounded-[20px]" src="/imgs/hoolywoodsnapbox-dancing-event.png" alt="" />
                    </div>
                    <div className="text-white flex flex-col justify-center gap-y-4 lg:gap-y-8">
                        <h2 className="text-3xl lg:text-5xl font-semibold">
                            Making Memories, One Snap at a Time
                        </h2>
                        <p className="lg:text-lg">
                            Whether you're hosting a wedding, corporate event, birthday party, or any other special occasion, our state-of-the-art 360-degree photobooths capture every angle and every smile. 
                        </p>
                        <p className="lg:text-lg">
                            From instant printouts to digital sharing options, we offer a variety of customizable features to suit your needs. Explore our services and see how we can make your next event unforgettable.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
};
