import { PiCopyrightLight } from "react-icons/pi";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import Logo from "./logo.component";
import { Link } from "react-router-dom";

export default function Footer() {
    
    return (
        <footer className="py-4 bg-gray-100 ">
            <div className="container divide-y-2">
                <div className="py-4 flex flex-wrap items-center justify-between gap-y-4">
                    <div className="max-sm:w-full flex flex-wrap justify-center">
                        <Logo />
                    </div>
                    <nav className="flex max-sm:flex-1 max-sm:justify-center items-center gap-4 md:text-lg text-[#0C1A64]">
                        <Link to="">
                            Home
                        </Link>
                        <Link to="services">
                            Services
                        </Link>
                        <Link to="contact">
                            Contact
                        </Link>
                        <Link to="faq">
                            Faq
                        </Link>
                    </nav>
                    <div className="flex max-sm:flex-1 justify-center sm:justify-end items-center gap-4 text-xl">
                        <Link to="">
                            <FaFacebook />
                        </Link>
                        <Link to="">
                            <FaInstagram />
                        </Link>
                        <Link to="">
                            <FaYoutube />
                        </Link>
                    </div>
                </div>
                <div className="py-4">
                    <p className="max-md:text-sm text-center">
                        <PiCopyrightLight className="inline -mt-1" /> { new Date(Date.now()).getFullYear() } Hollywood SnapBox. Allright reserved
                    </p>
                </div>
            </div>
        </footer>
    )
};
