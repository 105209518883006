import { useCallback, useEffect, useState } from "react"
import { FaCircle } from "react-icons/fa"
import { FaCakeCandles } from "react-icons/fa6"

type props = {
    children : any
    onCloseButton?: () => void
    showCloseButton?: boolean
}

export default function CustomModal({children, onCloseButton, showCloseButton} : props){
    const [isDisplaying, setIsDisplaying] = useState(true)

    const onCloseButtonClicked = useCallback( () => {

        onCloseButton && onCloseButton()
    }, [onCloseButton])

    useEffect( () => {
        if(isDisplaying){
            document.body.classList.add("h-screen", "overfow-y-hidden")
        }else{
            document.body.classList.remove("h-screen", "overfow-y-hidden")
        }

    }, [isDisplaying])

    return (
        <div className="bg-black/80 fixed inset-0 w-screen h-screen z-50 flex justify-center items-center">
            <div className=" mx-auto max-sm:w-[95%] sm:max-w-3xl sm:min-w-[400px] bg-white relative px-4 py-8 md:py-10">
                {
                    showCloseButton && (
                        <button className="hover:bg-black/90 hover:text-red-600 absolute flex items-center justify-center text-xl top-0 right-1/2 sm:right-0 -translate-y-1/2 translate-x-1/2 text-white bg-red-600 w-8 h-8 rounded-full" onClick={onCloseButtonClicked}>
                            {/* <FaCakeCandles /> */}
                            x
                        </button>
                    )
                }
                {children}
            </div>
        </div>
    )
}