type props = {
    bgImageUrl?: string
    pageTitle : string
    title : string
    maxTitleWidth?: string
    description: string
}

export default function Breadcrumb({bgImageUrl = "/imgs/hollywoodsnapbox-sunset.png",maxTitleWidth="", pageTitle, title, description} : props) {
    return (
        <section className="relative py-16 md:py-20">
            <img className="absolute inset-0 w-full h-full object-cover object-center" src={bgImageUrl} alt="" />
            <div className="absolute inset-0 w-full h-full bg-black/60" />
            <div className="container relative text-white">
                <div className="max-w-2xl flex flex-col gap-y-6">                        
                    <span className="text-sm pl-4 pr-10 py-2 w-fit bg-white text-[#0C1A64] rounded-tr-full">
                        {pageTitle}
                    </span>
                    <h1 className={`text-4xl md:text-5xl lg:text-6xl font-semibold text-white ${maxTitleWidth}`}>
                        {title}
                    </h1>
                    <p className="md:text-lg max-w-xl">
                        {description}
                    </p>
                </div>
            </div>
        </section>
    )   
}
