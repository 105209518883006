import { motion } from "framer-motion";
import Logo from "./logo.component";
import { useEffect, useState } from "react";
import { RiMenu3Line } from "react-icons/ri";
import { FaWindowClose } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaPhone } from "react-icons/fa6";
import { MdMail } from "react-icons/md";
export default function MobileMenu(){
    const [isToggled, setIsToggled] = useState(false)
    const location = useLocation()

    const mobileMenuVariant = {
        opened: {
            x: "0%",
            transition: {
                delay: 0.15,
                duration: 0.5,
                // ease: [0.5, 0, 0.19, 1.02]
            }
        },
        closed: {
            x: "100%",            
            transition: {
                delay: 0.35,
                duration: 0.5,
                ease: [0.74, 0, 0.19, 1.02]
            }
        }
    }

    useEffect( () => {
        setIsToggled(false)
    }, [location.pathname])

    return (
        <div className="flex lg:hidden items-center">
            <button className="" onClick={ () => setIsToggled(true) }>
                <RiMenu3Line size={20} />
            </button>
            <motion.div
                initial="closed"
                animate={isToggled ? "opened" : "closed"} 
                variants={mobileMenuVariant} 
                className="absolute inset-0 w-screen h-screen bg-white lg:hidden"
            >
                <div className="absolute inset-0 w-full h-full" />
                <div className="container relative h-full flex flex-col justify-center items-center ">
                        <button className="absolute top-10 text-red-600 text-2xl" onClick={ () => setIsToggled(false)}>
                            <FaWindowClose />
                        </button>
                    <div className="flex items-center justify-center">
                        <Logo classNames="max-sm:h-16" />
                    </div>
                    <motion.nav className="flex flex-col divide-y-[1px] divide-blue-600/90 items-center">
                        <div className="py-6">
                            <NavLink to="/" className={ ({isActive}) => `${isActive ? "text-blue-600 font-semibold" : "" }` }>Get back to the Home page</NavLink>
                        </div>
                        <div className="py-6">
                            <NavLink to="services" className={ ({isActive}) => `${isActive ? "text-blue-600 font-semibold" : "" }` }>Check out our Services</NavLink>
                        </div>
                        <div className="py-6">
                            <NavLink to="contact" className={ ({isActive}) => `${isActive ? "text-blue-600 font-semibold" : "" }` }>Contact us & stay n touch</NavLink>
                        </div>
                        <div className="py-6">
                            <NavLink to="faq" className={ ({isActive}) => `${isActive ? "text-blue-600 font-semibold" : "" }` }>Frequently Asked Questions</NavLink>
                            {/* <NavLink to="faq" className={ ({isActive}) => `${isActive ? "text-blue-600 font-semibold" : "" }` }>Check the Frequently Asked Questions</NavLink> */}
                            {/* <NavLink to="faq" className={ ({isActive}) => `${isActive ? "text-blue-600 font-semibold" : "" }` }>Check the F.A.Q and find answer to your qestion</NavLink> */}
                        </div>
                    </motion.nav>

                    <div className="">
                        <Link to="book" className="py-3 px-5 bg-blue-600 text-white inline-flex">Book for an event</Link>
                    </div>

                    <div className="mt-10 flex flex-col items-center gap-y-4">
                        <div className="flex items-center gap-x-2">
                            <FaPhone className="text-2xl text-blue-600"/>
                            <a href="tel:+12132459352" className="">+1 (213) 245-9352</a>
                        </div>
                        <div className="flex items-center gap-x-2">
                            <MdMail className="text-3xl text-blue-600"/>
                            <a href="mailto:contact@hollywoodsnapbox.com" className="">contact@hollywoodsnapbox.com</a>
                        </div>
                    </div>
                </div>

            </motion.div>
        </div>
    )
}