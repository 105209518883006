import { Breadcrumb } from "../components";

export default function FaqPage(){
    return (
        <>
            <Breadcrumb
                pageTitle="F.A.Q"
                title="Have Questions? We've Got Answers!"
                description="Whether you’re curious about our booking process, the features of our 360-degree photobooths, or need technical assistance, you’ll find the answers here"
                // bgImageUrl=""
            />

            <section className="py-16 md:py-20 lg:py-28">
                <div className="container flex flex-col gap-y-10">
                    <div className="flex flex-col items-center text-center gap-y-4 lg:gap-y-6">
                        <h2 className="text-3xl lg:text-5xl font-semibold text-[#0C1A64]">
                            Frequently Asked Questions
                        </h2>
                        <p className="lg:text-lg max-w-xl">
                            Here, we’ve compiled a list of the most commonly asked questions to help you understand our photobooth services better.
                        </p>
                    </div>
                        
                    <div className="grid md:grid-cols-2 gap-4 lg:gap-6">
                        
                        <div className="flex flex-col gap-2">
                            <h3 className="text-xl text-[#0C1A64] font-semibold">
                                How does it work?
                            </h3>
                            <p className="lg:text-lg text-[#444D81]">
                                Our 360-Degree Booths capture stunning photos and videos from every angle, creating immersive experiences for your events. Our team will set up the booth, guide your guests, and ensure they have a memorable time.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <h3 className="text-xl text-[#0C1A64] font-semibold">
                                How does it work?
                            </h3>
                            <p className="lg:text-lg text-[#444D81]">
                                Our 360-Degree Booths capture stunning photos and videos from every angle, creating immersive experiences for your events. Our team will set up the booth, guide your guests, and ensure they have a memorable time.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <h3 className="text-xl text-[#0C1A64] font-semibold">
                                How does it work?
                            </h3>
                            <p className="lg:text-lg text-[#444D81]">
                                Our 360-Degree Booths capture stunning photos and videos from every angle, creating immersive experiences for your events. Our team will set up the booth, guide your guests, and ensure they have a memorable time.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <h3 className="text-xl text-[#0C1A64] font-semibold">
                                How does it work?
                            </h3>
                            <p className="lg:text-lg text-[#444D81]">
                                Our 360-Degree Booths capture stunning photos and videos from every angle, creating immersive experiences for your events. Our team will set up the booth, guide your guests, and ensure they have a memorable time.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <h3 className="text-xl text-[#0C1A64] font-semibold">
                                How does it work?
                            </h3>
                            <p className="lg:text-lg text-[#444D81]">
                                Our 360-Degree Booths capture stunning photos and videos from every angle, creating immersive experiences for your events. Our team will set up the booth, guide your guests, and ensure they have a memorable time.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <h3 className="text-xl text-[#0C1A64] font-semibold">
                                How does it work?
                            </h3>
                            <p className="lg:text-lg text-[#444D81]">
                                Our 360-Degree Booths capture stunning photos and videos from every angle, creating immersive experiences for your events. Our team will set up the booth, guide your guests, and ensure they have a memorable time.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <h3 className="text-xl text-[#0C1A64] font-semibold">
                                How does it work?
                            </h3>
                            <p className="lg:text-lg text-[#444D81]">
                                Our 360-Degree Booths capture stunning photos and videos from every angle, creating immersive experiences for your events. Our team will set up the booth, guide your guests, and ensure they have a memorable time.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <h3 className="text-xl text-[#0C1A64] font-semibold">
                                How does it work?
                            </h3>
                            <p className="lg:text-lg text-[#444D81]">
                                Our 360-Degree Booths capture stunning photos and videos from every angle, creating immersive experiences for your events. Our team will set up the booth, guide your guests, and ensure they have a memorable time.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <h3 className="text-xl text-[#0C1A64] font-semibold">
                                How does it work?
                            </h3>
                            <p className="lg:text-lg text-[#444D81]">
                                Our 360-Degree Booths capture stunning photos and videos from every angle, creating immersive experiences for your events. Our team will set up the booth, guide your guests, and ensure they have a memorable time.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <h3 className="text-xl text-[#0C1A64] font-semibold">
                                How does it work?
                            </h3>
                            <p className="lg:text-lg text-[#444D81]">
                                Our 360-Degree Booths capture stunning photos and videos from every angle, creating immersive experiences for your events. Our team will set up the booth, guide your guests, and ensure they have a memorable time.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <h3 className="text-xl text-[#0C1A64] font-semibold">
                                How does it work?
                            </h3>
                            <p className="lg:text-lg text-[#444D81]">
                                Our 360-Degree Booths capture stunning photos and videos from every angle, creating immersive experiences for your events. Our team will set up the booth, guide your guests, and ensure they have a memorable time.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <h3 className="text-xl text-[#0C1A64] font-semibold">
                                How does it work?
                            </h3>
                            <p className="lg:text-lg text-[#444D81]">
                                Our 360-Degree Booths capture stunning photos and videos from every angle, creating immersive experiences for your events. Our team will set up the booth, guide your guests, and ensure they have a memorable time.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <h3 className="text-xl text-[#0C1A64] font-semibold">
                                How does it work?
                            </h3>
                            <p className="lg:text-lg text-[#444D81]">
                                Our 360-Degree Booths capture stunning photos and videos from every angle, creating immersive experiences for your events. Our team will set up the booth, guide your guests, and ensure they have a memorable time.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <h3 className="text-xl text-[#0C1A64] font-semibold">
                                How does it work?
                            </h3>
                            <p className="lg:text-lg text-[#444D81]">
                                Our 360-Degree Booths capture stunning photos and videos from every angle, creating immersive experiences for your events. Our team will set up the booth, guide your guests, and ensure they have a memorable time.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <h3 className="text-xl text-[#0C1A64] font-semibold">
                                How does it work?
                            </h3>
                            <p className="lg:text-lg text-[#444D81]">
                                Our 360-Degree Booths capture stunning photos and videos from every angle, creating immersive experiences for your events. Our team will set up the booth, guide your guests, and ensure they have a memorable time.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <h3 className="text-xl text-[#0C1A64] font-semibold">
                                How does it work?
                            </h3>
                            <p className="lg:text-lg text-[#444D81]">
                                Our 360-Degree Booths capture stunning photos and videos from every angle, creating immersive experiences for your events. Our team will set up the booth, guide your guests, and ensure they have a memorable time.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <h3 className="text-xl text-[#0C1A64] font-semibold">
                                How does it work?
                            </h3>
                            <p className="lg:text-lg text-[#444D81]">
                                Our 360-Degree Booths capture stunning photos and videos from every angle, creating immersive experiences for your events. Our team will set up the booth, guide your guests, and ensure they have a memorable time.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}